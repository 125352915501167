@font-face {
  font-family: 'SUIT-Medium';
  src: url('./common/assets/fonts/SUIT-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT-Regular';
  src: url('./common//assets/fonts/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* #2b66bd */
:root {
  --color-main: rgb(85, 87, 92);
  --color-main2: #1B1B1B;

  --color-high1: #00C6CF;
  --color-high2: #3565c9;
  --color-high3: #068488;
  --color-high4: #324B4C;

  --color-high-rgb: rgb(0, 198, 207);

  --font-main: 'SUIT-Medium';
  --font-sub: 'SUIT-Regular';

  --border-header: #828590;
  --border-main: #E7E6E8;
  --border-sub: #e4e8eb;
  --border-goo: #fcfcfc;
  --border-result: #e8ebee;

  --js-color: #1a3866;

  --blue-color-1: #ccd9f6;
  --blue-color-2: #aac1f0;

  --chroma-green: rgb(0, 255, 27);

  --font-color-1: rgb(205, 205, 205);

  --height-menu: 45px;

  --box-shadow-b5: 0 2px 5px 1px rgb(64 60 67 / 16%);
  --box-shadow-2: -1px 0 6px rgb(0 0 0 / 20%);
  --box-shadow-3: 0 1px 6px rgb(32 33 36 / 28%);
  --box-shadow-3-hover: 0 1px 6px rgb(0 198 207 / 32%);
  --box-shadow-header-hover: 0 3px 6px rgb(0 198 207 / 32%);
  --box-shadow-b3: 0 1px 3px 0 rgba(0,0,0,.12);

  --home-left-width: 800px;
  --home-right-width: 350px;
}

body {
  font-family: '굴림';
}

.fHyper {
  color: #0000EE;
  font-weight: 600;
  text-decoration: underline;

  cursor: pointer;
}

.fSize_16 {
  font-size: 16px;
}

.fSize_13 {
  font-size: 13px;
}

.w780 {
  width: 780px;
}

a:visited {
  color: #0000ee;
}

.mb8 {
  margin-bottom: 8px;
}

.Search {
  box-sizing: border-box;
  width: 100%;

  padding: 5px 25px;

  display: flex;
  justify-content: center;
}

.Search_Container {
  display: flex;
  flex-direction: column;

  width: 780px;
  /* width: 100%; */
}

#search_top_border {
  width: 100%;
  height: 5px;
  background-color: #a8a8a8;
}

#search_top_adv {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  margin: 16px 0;

  /* padding: 0 18vw; */
}

#search_top_adv>a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
}

#sta_center {
  width: 20%;
  max-width: 330px;

  height: 100%;
}

.search_top_adv_b {
  color: #000;
  font-size: 10pt;
  margin-top: 3px;
}

.search_basic {
  font-size: 9pt;
  font-family: '굴림';
}

.txt_fff {
  font-size: 10pt;
  color: #fff;
}

.li_blue008:link,
.li_blue008:visited {
  text-decoration: none;
  color: #000a82;

  font-size: 10pt;
}

.li_blue04a,
.li_blue04a:link,
.li_blue04a:visited {
  color: #0e49a3;
  text-decoration: underline;
  font-weight: 600;
}

.li_blue02b,
.li_blue02b:link,
.li_blue02b:visited {
  color: #002eb0;
}

.li_rede90,
.li_rede90:link,
.li_rede90:visited {
  color: #e99600;
  text-decoration: underline;
}

#search_margin {
  margin-top: -10px;
  height: 10px;
  background-color: #fff;
  margin-bottom: 4px;
}

#search_box {
  display: flex;
}

#search_box_l {
  width: 180px;
  box-sizing: border-box;

  margin-right: 20px;
}

.search_left_li>.li_blue02b {
  margin-left: 10px;
}

#search_box_r {
  display: flex;
  flex-direction: column;

  flex: 1 0 0;
}

.sbb_head {
  display: flex;
  align-items: center;
}

.sb_block {
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  margin-bottom: 30px;
}

.sbb_head>img {
  margin-right: 8px;
}

.sbb_head_t {
  color: #3d5ed2;
  text-decoration: underline;
  cursor: pointer;
}

.sbb_text {
  margin-top: 14px;
  font-size: 10pt;
  line-height: 14pt;

  display: -webkit-box;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sbb_loca {
  color: #249c20;
  font-size: 10pt;
  margin-top: 8px;
}

.sbb_text:hover,
.sbb_loca:hover {
  cursor: pointer;
  text-decoration: underline;
}

.search_more {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 9pt;

  margin-top: 30px;
  cursor: pointer;
  color: #002eb0;
  text-decoration: underline;

  margin-bottom: 50px;
}
.Container {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
}

#home_header_menu {
  background-color: #000;
  height: 10px;
}

.home_header_menu {
  font-size: 13.3px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.home_header_menu:hover {
  text-decoration: underline;
}

.tit {
  font-size: 10pt;
}

.fUp {
  font-size: 11px;
  color: #ff0000;
  font-weight: 600;
  letter-spacing: -1px;
}

#header_high {
  display: flex;
  align-items: center;

  height: 120px;
  box-sizing: border-box;

  margin: 8px 0;
}

.hh_left {
  margin-top: 2px;
}

.hBasic:hover {
  text-decoration: underline;
  cursor: pointer;
}

.home_header_high_1 {
  width: 195px;
  box-sizing: border-box;
  padding-right: 5px;
}

.red {
  font-size: 8pt;
  font-family: arial;
}

.home_header_s {
  font-size: 8pt;
}

#home_search {
  font-size: 10pt;
  /* padding: 0; */
}

#home_main {
  display: flex;
}

#home_main_l {
  width: 204px;

  flex: 204px 0 0;

  box-sizing: border-box;
  margin: 8px;
  margin-bottom: 0;

  background-color: #ECECEC;
}

#home_main_r {
  flex: 1 0 0;
  padding: 8px;
  padding-left: 0;
}

.login2 {
  color: #000 !important;
}

.mylink {
  color: #313131 !important;
  font-size: 10pt;
}

.hml_top {
  margin-top: 20px;
  margin-left: 8px;
  margin-bottom: 8px;

  font-weight: 600;
  text-decoration: underline;

  cursor: pointer;

  font-size: 10pt;
}

.hml_bot {
  display: flex;
  align-items: center;

  font-size: 10pt;

  margin-bottom: 8px;
}

.hml_bot>img {
  margin-left: 6px;
}

.hml_bot_t {
  margin-left: 5px;
  text-decoration: underline;

  cursor: pointer;
}

.hmr_top {
  background-color: #ececec;
  padding: 4px 8px;
  font-size: 10pt;
  font-weight: 600;

  margin-bottom: 4px;
}

.hmr_top>span {
  font-weight: 400;
}

.hmr_box {
  display: flex;

  padding: 4px 8px;
  padding-right: 0;
  font-size: 10pt;

  margin-bottom: 8px;
}

.hmr_box_2 {
  padding-top: 8px;
  border-top: dashed 1px #000;
}

.hmr_box_line,
.hrm_box_list_l {
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
}

.hmr_box_list {
  display: flex;
  margin-bottom: 12px;
}

.hrm_box_list_l {
  width: 70px;
}

.hmr_box_l,
.hrm_box_list_rr {
  flex: 1 0 0;
}

.hrm_box_list_r>div>span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.hmr_top_btn {
  text-decoration: underline;
  cursor: pointer;
}

#home_footer {
  width: 780px;
  margin-top: 16px;
  padding-top: 10px;
  border-top: solid 1px #999;
  height: 60px;
}